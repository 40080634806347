import { useState } from 'react';
import styles from './SchoolItem.module.css';
import {School} from '../../types/interfaces';

type Props = {
    school: School;
}

const SchoolItem = ({school} : Props) => {

    const [expanded, setExpanded] = useState(false);
    const {schoolDegree, schoolDesc} = school;

    const onActionBtn = () => {
        setExpanded(!expanded);
    }

    return (
        <li className={styles.schoolItem} onClick={() => onActionBtn()}>
            <div className={styles.schoolHeadGroup} style={{background: expanded ? '#2c98f0' : '#f2f3f7', color: expanded ? '#fff' : '#000'}}>
                <h4 className={styles.schoolHeader}>{schoolDegree}</h4>
                <button className={`${styles.schoolExpandBtn} ${expanded ? styles.hideBtn : styles.expandBtn}`}>{expanded ? '-' : '+'}</button>
            </div>
            {
                expanded && 
                <div className={styles.schoolDetailGroup}>
                    <p className={styles.schoolDesc}>{schoolDesc}</p>
                </div>
            }
        </li>
    )
}

export default SchoolItem;