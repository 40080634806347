import styles from './Sidebar.module.css';
import {Link} from 'react-scroll';
import { RESOURCES_BUCKET_BASE_URL } from '../util/env';

const Sidebar = () => {
  return (
    <div className={styles.sidebar}>
        <img src={`${RESOURCES_BUCKET_BASE_URL}/Title.jpg`} alt="My Portrait" className={styles.portrait}/>
        <h2 className={styles.pageHeader}>Tyler Teague</h2>
        <ul className={styles.navList}>
            <li className={styles.navItem}>
              <Link activeClass='' to="heroSection" spy={false} smooth={true} offset={-70} duration={500}>HOME</Link>
            </li>
            <li className={styles.navItem}>
              <Link activeClass='' to="aboutSection" spy={false} smooth={true} offset={-70} duration={500}>ABOUT</Link>
            </li>
            <li className={styles.navItem}>
              <Link activeClass='' to="skillSection" spy={false} smooth={true} offset={-70} duration={500}>SKILLS</Link>
            </li>
            <li className={styles.navItem}>
              <Link activeClass='' to="schoolSection" spy={false} smooth={true} offset={-70} duration={500}>EDUCATION</Link>
            </li>
            <li className={styles.navItem}>
              <Link activeClass='' to="workSection" spy={false} smooth={true} offset={-70} duration={500}>EMPLOYMENT</Link>
            </li>
            <li className={styles.navItem}>
              <Link activeClass='' to="certSection" spy={false} smooth={true} offset={-70} duration={500}>CERTIFICATIONS</Link>
            </li>
            <li className={styles.navItem}>
              <Link activeClass='' to="projectSection" spy={false} smooth={true} offset={-70} duration={500}>PROJECTS</Link>
            </li>
            <li className={styles.navItem}>
              <Link activeClass='' to="contactSection" spy={false} smooth={true} offset={-70} duration={500}>CONTACT</Link>
            </li>
        </ul>
    </div>
  )
}

export default Sidebar;