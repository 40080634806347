import SchoolItem from '../items/SchoolItem';
import styles from './SchoolSection.module.css';
import {School} from '../../types/interfaces';

type Props = {
  schools: Array<School>;
}

const SchoolSection = ({schools} : Props) => {
  return (
    <section id='schoolSection' className={styles.section}>
        <p className={styles.sectionPrimer}>MY KNOWLEDGE</p>
        <h3 className={styles.sectionHeader}>WHERE DID I LEARN?</h3>
        <ul className={styles.schoolList}>
        {
        schools && schools.map((s,idx) => (
            <SchoolItem key={idx} school={s}/>
        ))
        }
        </ul>
    </section>
  )
}

export default SchoolSection;