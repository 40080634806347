import styles from './WorkItem.module.css';
import {Work} from '../../types/interfaces';

type Props = {
  work: Work;
}

const WorkItem = ({work} : Props) => {
  return (
    <li className={styles.workItem}>
        <div className={styles.workLines}>
            <div className={styles.horiz}></div>
            <div className={styles.vert}></div>
            <div className={styles.circ}></div>
        </div>
        <div className={styles.workContent}>
            <h4 className={styles.workHeader}>{work.workName} <span  className={styles.timespan}>{work.workDates}</span></h4>
            <p className={styles.workDesc}>{work.workDesc}</p>
        </div>
    </li>
  )
}

export default WorkItem;