import AboutSection from '../components/sections/AboutSection';
import CertSection from '../components/sections/CertSection';
import ContactSection from '../components/sections/ContactSection';
import HeroSection from '../components/sections/HeroSection';
import ProjectSection from '../components/sections/ProjectSection';
import SchoolSection from '../components/sections/SchoolSection';
import Sidebar from '../components/Sidebar';
import SkillSection from '../components/sections/SkillSection';
import WorkSection from '../components/sections/WorkSection';
import styles from './App.module.css';

import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../util/env';
import {Content} from '../types/interfaces';

function App() {

  const [content, setContent] = useState<Content | null>(null);

  useEffect(() => {
      fetchContent();
  }, [])

  const fetchContent = async () => {
  
    try {
      let res = await axios.get(`${API_BASE_URL}/content`);
      setContent(res.data);
    } catch (error) {
      
    }
  }

  if(!content){
    return null;
  }

  return (
    <div className={styles.appPage}>
      <Sidebar />
      <div className={styles.appContent}>
        <HeroSection />
        <AboutSection stories={content.stories}/>
        <SkillSection skills={content.skills}/>
        <SchoolSection schools={content.schools}/>
        <WorkSection jobs={content.jobs}/>
        <CertSection />
        <ProjectSection projects={content?.projects}/>
        <ContactSection />
      </div>
    </div>
  );
}

export default App;
