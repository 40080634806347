import { RESOURCES_BUCKET_BASE_URL } from '../../util/env';
import styles from './HeroSection.module.css'

const HeroSection = () => {
  return (
    <section id='heroSection' className={styles.heroSection}>
        <h1 className={styles.heroHeader}>Full-Stack Web Developer</h1>
        <p className={styles.heroDesc}>Learning Every Day</p>
        <a className={styles.heroBtn} href={`${RESOURCES_BUCKET_BASE_URL}/Tyler+Teague+Resume+V4.2.pdf`} target="_blank" rel='noreferrer'>Download CV</a>
    </section>
  )
}

export default HeroSection;