import styles from './WorkSection.module.css';
import WorkItem from '../items/WorkItem';
import {Work} from "../../types/interfaces";

type Props = {
  jobs: Array<Work>;
}

const WorkSection = ({jobs} : Props) => {
  return (
    <section id='workSection' className={styles.section}>
        <p className={styles.sectionPrimer}>MY EXPERIENCE</p>
        <h3 className={styles.sectionHeader}>WHERE HAVE I WORKED?</h3>
        <div className={styles.cap}>
            <div className={styles.topCirc}></div>
            <div className={styles.line}></div>
        </div>
        <ul className={styles.workList}>
        {
            jobs && jobs.map((w,idx) => (
            <WorkItem key={idx} work={w}/>
            ))
        }
        </ul>
        <div className={styles.cap}>
            <div className={styles.bottomCirc}></div>
            <div className={styles.line}></div>
        </div>
    </section>
  )
}

export default WorkSection;