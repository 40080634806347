import styles from './ProjectSection.module.css';
import {Project} from '../../types/interfaces';

type Props = {
  projects: Array<Project>;
}

const ProjectSection = ({projects}: Props) => {
  return (
    <section id='projectSection' className={styles.section}>
        <p className={styles.sectionPrimer}>MY PROJECTS</p>
        <h3 className={styles.sectionHeader}>WHAT HAVE I WORKED ON?</h3>
        <ul className={styles.projectList}>
          {
            projects && projects.map((p,idx) => (
              <li key={idx} className={styles.projectItem} style={{backgroundColor: p.projectColor}}>
                  <h4 className={styles.projectHeader}>{p.projectName}</h4>
                  <div className={styles.projectLinks}>
                    {p.projectSiteLink && <a className={styles.externalLink} href={p.projectSiteLink} target="_blank" rel='noreferrer'>View Project</a>}
                    {p.projectSourceLink && <a className={styles.externalLink} href={p.projectSourceLink} target="_blank" rel='noreferrer'>View Source</a>}
                  </div>
              </li>
            ))
          }
        </ul>
    </section>
  )
}

export default ProjectSection;