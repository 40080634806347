import axios from 'axios';
import { useState } from 'react';
import styles from './ContactSection.module.css';
import { API_BASE_URL } from '../../util/env';

const ContactSection = () => {

    const [name, setName] = useState<string>();
    const [subject, setSubject] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [message, setMessage] = useState<string>();
    const [delivered, setDelivered] = useState<boolean>(false);

    const onSubmitBtn = async () => {

        let body = {
            firstName: name,
            subject,
            email,
            message
        }

        try {
            await axios.post(`${API_BASE_URL}/message`, body);

        } catch (error) {
            console.log("Error sending message!");
        }

        setName("");
        setSubject("");
        setEmail("");
        setMessage("");
        setDelivered(true);
    }

    return (
        <section id='contactSection' className={styles.section}>
            <p className={styles.sectionPrimer}>CONTACT</p>
            <h3 className={styles.sectionHeader}>HOW CAN YOU CONTACT ME?</h3>
            <div className={styles.inputForm}>
                <div className={styles.inputGroup}>
                    <label htmlFor="name" className={styles.inputLabel}>Name</label>
                    <input type="text" name="name" id="name" className={styles.input} value={name} onChange={(e) => setName(e.target.value)}/>
                </div>
                <div className={styles.inputGroup}>
                    <label htmlFor="email" className={styles.inputLabel}>Email</label>
                    <input type="text" name="email" id="email" className={styles.input} value={email} onChange={(e) => setEmail(e.target.value)}/>
                </div>
                <div className={styles.inputGroup}>
                    <label htmlFor="subject" className={styles.inputLabel}>Subject</label>
                    <input type="text" name="subject" id="subject" className={styles.input} value={subject} onChange={(e) => setSubject(e.target.value)}/>
                </div>
                <div className={styles.inputGroup}>
                    <label htmlFor="message" className={styles.inputLabel}>Message</label>
                    <textarea name="message" id="message" className={styles.inputArea} value={message} onChange={(e) => setMessage(e.target.value)}/>
                </div>
                <button className={styles.submitBtn} onClick={() => onSubmitBtn()}>Submit</button>
                {
                    delivered && <p className={styles.sentMsg}>Message Sent!</p>
                }
            </div>
        </section>
    )
}

export default ContactSection;