import styles from './CertSection.module.css';

const CertSection = () => {

  return (
    <section id='certSection' className={styles.section}>
        <p className={styles.sectionPrimer}>MY CERTIFICATIONS</p>
        <h3 className={styles.sectionHeader}>WHAT CERTIFICATIONS DO I POSSESS?</h3>
        <ul className={styles.certList}>
            <li className={styles.certItem}>
                <img src="https://tylerdteague-resources-bucket.s3.amazonaws.com/solutions-architect-badge.png" alt="AWS Solutions Architect Associate" className={styles.certImage}/>
            </li>
            <li className={styles.certItem}>
                <img src="https://tylerdteague-resources-bucket.s3.amazonaws.com/developer-badge.png" alt="AWS Developer Associate" className={styles.certImage}/>
            </li>
            <li className={styles.certItem}>
                <img src="https://tylerdteague-resources-bucket.s3.amazonaws.com/sysops-admin-badge.png" alt="AWS Sysops Admin Associate" className={styles.certImage}/>
            </li>
        </ul>
    </section>
  )

}

export default CertSection;