import styles from './SkillSection.module.css';

type Props = {
  skills: Array<Array<String>>;
}

const SkillSection = ({skills} : Props) => {
  return (
    <section id='skillSection' className={styles.section}>
        <p className={styles.sectionPrimer}>MY SKILLS</p>
        <h3 className={styles.sectionHeader}>WHAT TECHNOLOGIES HAVE I USED?</h3>
        <div className={styles.skillContent}>
        {
          skills && skills.map((s,idx) => (
            <ul key={idx} className={styles.skillList}>
              {
                s && s.map((i, idx) => (
                  <li key={idx} className={styles.skillItem}>{i}</li>
                ))
              }
            </ul>
          ))
        }
        </div>
    </section>
  )
}

export default SkillSection;