import styles from './AboutSection.module.css';

type Props = {
  stories: Array<string>;
}

const AboutSection = ({stories} : Props) => {

  return (
    <section id='aboutSection' className={styles.section}>
        <p className={styles.sectionPrimer}>MY STORY</p>
        <h3 className={styles.sectionHeader}>WHO AM I?</h3>
        {
          stories && stories.map((s,i) => (
            <p key={i} className={styles.aboutDesc}>{s}</p>
          ))
        }
    </section>
  )
}

export default AboutSection;